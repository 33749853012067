import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const LoadingModal = ({ show }) => {
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body className="text-center">
        <Spinner animation="border" role="status">
        </Spinner>
        <div>Loading, please wait...</div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
