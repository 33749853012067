import React, { useState, useEffect } from 'react';
import { Container, Box, CssBaseline } from '@mui/material';
import axios from 'axios';
import styled from 'styled-components';
import LoadingModal from './LoadingModal';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Split from 'react-split';
import logo from './athelas_logo.png';
import avatar from './chatbot_avatar.png';
import user_logo from './user_logo.png';
// import {
//   MainContainer,
//   ChatContainer,
//   MessageList,
//   Message,
//   MessageInput,
//   Avatar,
//   TypingIndicator
// } from '@chatscope/chat-ui-kit-react/';
// import {
//   MainContainer,
//   ChatContainer,
//   MessageList,
//   MessageInput,
//   Avatar,
//   TypingIndicator
// } from '@chatscope/chat-ui-kit-react/';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    Avatar,
    TypingIndicator
  } from './components/';
// import {
//   Message,
// } from './components/';
// import 'react-split/style.css'; // Import the Split pane styles
import './chat.css';
import './pdfViewer.css';

const Header = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #ccc;
  height: 60px; /* Set a fixed height for the header */
`;

const Logo = styled.img`
  height: 50px;
`;

const ViewerWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const ChatWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

function App() {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [thread, setThread] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [currentRun, setCurrentRun] = useState(null);
  const [error, setError] = useState(false);

  function timeDifference(current, previous) {
    var msPerMinute = 60;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;
    if (Math.floor(elapsed) <= 0) {
      return 'just now';
    }
    if (elapsed < msPerMinute) {
      return Math.round(elapsed) + ' seconds ago';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
      return Math.floor(elapsed / msPerHour) + ' hour' + (Math.floor(elapsed / msPerHour) !== 1 ? 's ago' : ' ago');
    } else if (elapsed < msPerMonth) {
      return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
      return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
    }
  }

  function stripHTMLTags(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.textContent || "";
  }

  const handleNewUserMessage = async (newMessage) => {
    newMessage = stripHTMLTags(newMessage)
    const newMessageObject = { role: 'user', content: newMessage, sentTime: Date.now() / 1000 };
    setMessages((prevMessages) => [...prevMessages, newMessageObject]);
    setTyping(true);

    try {
      const formData = new FormData();
      formData.append('uuid', thread);
      formData.append('message', newMessage);

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/send_message`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const { run_id, message_id } = response.data;
      setCurrentRun({ run_id, message_id });
    } catch (error) {
      console.error('Error sending message', error);
    }
  };

  useEffect(() => {
    setLoading(true)
    const urlParams = new URLSearchParams(window.location.search);
    const threadId = urlParams.get('thread');
    if (threadId) {
      setThread(threadId);
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/get_file/${threadId}`)
        .then((response) => {
          if (response.status === 200) {
            const pdfBinary = response.data.file_content;
            const byteCharacters = atob(pdfBinary);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });
            setPdfUrl(URL.createObjectURL(blob));
            return axios.get(`${process.env.REACT_APP_BASE_URL}/get_messages/${threadId}`);
          }
          else {
            setError(true);
            setLoading(false);
            throw new Error("Thread does not exist");
          }
        })
        .then((response) => {
          const messages = response.data;
          setMessages(messages);
          setLoading(false)
        })
        .catch((error) => {
          console.error('Error fetching file or messages', error);
          setError(true);
          setLoading(false);
        });
    }
    else {
      console.error('No thread provided', error);
      setError(true);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentRun) return;

    let status = "queued";
    const checkRunStatus = async () => {
      while (status === "queued" || status === "in_progress") {
        try {
          const formData = new FormData();
          formData.append('uuid', thread);
          formData.append('run_id', currentRun.run_id);
          formData.append('message_id', currentRun.message_id);

          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/check_run`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });

          const { status: newStatus, new_messages } = response.data;
          status = newStatus;

          if (status === "completed" && new_messages) {
            setMessages((prevMessages) => [...prevMessages, ...new_messages]);
            setCurrentRun(null);
            setTyping(false);
            break;
          }

          await new Promise(resolve => setTimeout(resolve, 3000));
        } catch (error) {
          console.error('Error checking run status', error);
        }
      }
    };

    checkRunStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRun]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return !error ? (
    <Container component="main" maxWidth="xl">
      <LoadingModal show={loading} />
      <CssBaseline />
      <Header>
        <Logo src={logo} alt="Logo" />
      </Header>
      {pdfUrl && (
        <Split
          sizes={[50, 50]}
          minSize={100}
          expandToMin={false}
          gutterSize={10}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          style={{ height: 'calc(100vh - 60px)', display: 'flex' }}
        >
          <ViewerWrapper>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
              <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} />
            </Worker>
          </ViewerWrapper>
          <ChatWrapper>
            <MainContainer>
              <ChatContainer>
                <MessageList typingIndicator={typing ? <TypingIndicator content="ScanAI is typing" /> : null}>
                  {messages.map((msg, index) => (
                    <Message
                      key={index}
                      model={{
                        message: msg.content,
                        sentTime: msg.sentTime,
                        sender: msg.role === 'user' ? 'User' : 'Assistant',
                        direction: msg.role === 'user' ? 'outgoing' : 'incoming',
                        position: 'normal'
                      }}
                      markdown={msg.role === 'assistant'}
                      children={[
                        <Avatar src={msg.role === 'user' ? user_logo : avatar} size="sm" name="Joe" />,
                        <Message.Footer sentTime={timeDifference(Date.now() / 1000, msg.sentTime)} />
                      ]}
                      type="text"
                    />
                  ))}
                </MessageList>
                <MessageInput placeholder="Type your message here..." onSend={handleNewUserMessage} sendButton={false} attachButton={false} autoFocus={true} />
              </ChatContainer>
            </MainContainer>
          </ChatWrapper>
        </Split>
      )}
    </Container>
  ) : (
    <Container component="main" maxWidth="xl">
      <LoadingModal show={loading} />
      <CssBaseline />
      <Header>
        <Logo src={logo} alt="Logo" />
      </Header>
      <h1>404: PAGE NOT FOUND</h1>
    </Container>
  );
}

export default App;
